import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import Submit from '@/components/Forms/Submit';
import { appTrackerLink } from '@/store/models/contact';

import styles from './BottomSidebar.module.scss';

const BottomSidebar = ({
  alignLeft,
  user,
  onCancel,
  onSaveNext,
  onReset,
  resetEnable,
  onSubmit,
  onEdit,
  onSaveNew,
  onSaveNewAppTracker,
  onSave,
  submitText,
  disabledSubmit,
  disabledCancel = false,
  cancelText = 'Cancel'
}) => {
  return (
    <div className={classNames(styles.bottomSidebar, alignLeft && styles.alignLeft)}>
      <div className={styles.buttonsBody}>
        <div className={styles.buttonsWrapper}>
          {onCancel && (
            <button
              onClick={onCancel}
              disabled={disabledCancel}
              type="button"
              className="button is-large is-secondary is-auto"
            >
              {cancelText}
            </button>
          )}

          {onReset && (
            <button
              onClick={onReset}
              disabled={!resetEnable}
              type="button"
              className={classNames('button is-large is-secondary is-auto', {
                disabled: !resetEnable
              })}
            >
              Reset
            </button>
          )}

          {onSave && (
            <Submit
              disabled={disabledSubmit}
              label={'Save'}
              className="button is-large is-secondary is-auto"
              onSubmit={onSave}
            />
          )}

          {onSaveNew && (
            <Submit
              disabled={disabledSubmit}
              label={'Save & New'}
              className="button is-large is-secondary is-auto"
              onSubmit={onSaveNew}
            />
          )}
          {onSaveNewAppTracker && user?.aris && appTrackerLink(user) && (
            <Submit
              disabled={disabledSubmit}
              id="redirectAppTracker"
              label={'Save & App Tracker'}
              className="button is-large is-secondary is-auto"
              onSubmit={onSaveNewAppTracker}
            />
          )}
          {onSaveNext && (
            <Submit
              disabled={disabledSubmit}
              label={'Save & Next'}
              className="button is-large is-secondary is-auto"
              onSubmit={onSaveNext}
            />
          )}
          {onEdit && (
            <button onClick={onEdit} type="button" className="button is-large is-secondary is-auto">
              Edit
            </button>
          )}
          {onSubmit && (
            <Submit
              disabled={disabledSubmit}
              id="redirectSubmit"
              label={submitText ? submitText : 'Continue'}
              className="button is-large is-primary is-auto"
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(BottomSidebar);
