import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from '@/utils/errors';

const CurrencyInput = ({ id, disabled, name, className, placeholder, value, onChange, ...props }) => {
  useEffect(() => {
    if (value && !changed) {
      setMaskedValue(formatter.format(value.replace(/,/g, '')));
    } else if (!value && !changed) {
      setMaskedValue('');
    }
  });

  const [maskedValue, setMaskedValue] = useState(value);
  const [valid, setValid] = useState(true);
  const [changed, setChanged] = useState(false);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  const formatValue = e => {
    setChanged(true);
    if (valid) {
      const clearedValue = e.target.value.replace('$', '').replace(/,/g, '');
      if ((clearedValue.length <= 9 && clearedValue.includes('.')) || clearedValue.length <= 6) {
        onChange(clearedValue);
        e.target.value ? setMaskedValue('$' + '' + clearedValue) : setMaskedValue('');
      }
    }
  };

  const checkForValid = e => {
    const charCode = e.which || e.keyCode;
    const result = [44, 46, 8, 190, 48].includes(charCode) || !(charCode < 48 || charCode > 57);
    setValid(result);
  };

  const blurValue = () => {
    const clearedValue = maskedValue.replace('$', '').replace(/,/g, '');
    clearedValue ? setMaskedValue(formatter.format(clearedValue)) : setMaskedValue('');
    setChanged(false);
  };

  return (
    <>
      <input
        onBlur={blurValue}
        disabled={disabled}
        name={name}
        className={className}
        type="text"
        placeholder={placeholder}
        value={maskedValue}
        onKeyDown={checkForValid}
        onChange={formatValue}
        id={id}
        {...props}
      />
    </>
  );
};

export default withErrorBoundary(CurrencyInput);
